import { createFileRoute, Navigate } from "@tanstack/react-router";
import useAuthentication from "../hooks/useAuthentication";
import { useEffect } from "react";
import { logFormats } from "../utils/log-formats";

export const Route = createFileRoute("/domain")({
  validateSearch: (search: any) => ({
    redirect: search.redirect as string,
    token: search.token as string,
  }),
  component: RouteComponent,
});

function RouteComponent() {
  const { organization, isAuthenticated, isLoading } = useAuthentication();
  const search = Route.useSearch();
  // const navigate = Route.useNavigate();
  const targetPath =
    (isAuthenticated && search.redirect) ||
    (isAuthenticated ? "/" : "/auth/login");

  useEffect(() => {
    logFormats.app.info("Domain component state", {
      organization: organization?.id ? organization.id : "none",
      isAuthenticated,
      isLoading,
      search,
    });
  }, [isAuthenticated, isLoading, organization, search]);

  if (isLoading || !organization || !organization?.id) {
    return (
      <div className="flex flex-col justify-center items-center h-svh gap-4">
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <div className="text-center">
            {!organization?.id && (
              <>
                <h2 className="text-xl font-medium mb-2">Invalid Domain</h2>
                <p>Please use a valid subdomain to access the system.</p>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
  if (search?.token) {
    logFormats.app.navigation(Route.fullPath, "/auth/invite");
    return (
      <Navigate to={`/auth/invite`} search={{ token: search.token }} replace />
    ); // Redirect to the invite page with the token
  }
  logFormats.app.navigation(Route.fullPath, targetPath);
  return <Navigate to={targetPath} replace />; // Redirect to the target path
}
