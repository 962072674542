import { api } from "../queryClient";

// Log levels for different types of logs
export enum LogLevel {
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
  DEBUG = "debug",
}

// Interface for log entries
export interface LogEntry {
  level: LogLevel;
  message: string;
  context?: Record<string, any>;
  timestamp?: string;
  applicationName: string;
  version?: string;
  environment?: string;
  userId?: string;
}

// Configure throttling parameters
const THROTTLE_INTERVAL = 100; // 1 second between log sends

class LogWorker {
  private logQueue: LogEntry[] = [];
  private timer: NodeJS.Timeout | null = null;
  private applicationName: string;
  private version?: string;
  private isProcessing = false;

  constructor(applicationName: string, version?: string) {
    this.applicationName = applicationName;
    this.version = version;
  }

  /**
   * Add a log entry to the queue and trigger sending if needed
   */
  public log(
    level: LogLevel,
    message: string,
    context?: Record<string, any>
  ): void {
    const entry: LogEntry = {
      level,
      message,
      context,
      timestamp: new Date().toISOString(),
      applicationName: this.applicationName,
      version: this.version,
      // eslint-disable-next-line turbo/no-undeclared-env-vars
      environment: import.meta.env.MODE || "development",
    };

    this.logQueue.push(entry);
    this.startProcessing();
  }

  /**
   * Helper methods for common log levels
   */
  public info(message: string, context?: Record<string, any>): void {
    this.log(LogLevel.INFO, message, context);
  }

  public warn(message: string, context?: Record<string, any>): void {
    this.log(LogLevel.WARN, message, context);
  }

  public error(message: string, context?: Record<string, any>): void {
    this.log(LogLevel.ERROR, message, context);
  }

  public debug(message: string, context?: Record<string, any>): void {
    this.log(LogLevel.DEBUG, message, context);
  }

  /**
   * Start processing the log queue if not already processing
   */
  private startProcessing(): void {
    if (!this.isProcessing) {
      this.processNextLog();
    }
  }

  /**
   * Process the next log in the queue
   */
  private async processNextLog(): Promise<void> {
    if (this.logQueue.length === 0) {
      this.isProcessing = false;
      return;
    }

    this.isProcessing = true;
    const logEntry = this.logQueue.shift();

    try {
      if (logEntry) {
        await api.post("/log", logEntry);
      }
    } catch (error) {
      console.error("Failed to send log to server:", error);
      // Optionally re-queue important logs or implement a retry strategy
    }

    // Throttle the next log send to avoid overwhelming the server
    this.timer = setTimeout(() => {
      this.timer = null;
      this.processNextLog();
    }, THROTTLE_INTERVAL);
  }

  /**
   * Force send all pending logs immediately (useful before app closes)
   */
  public async flushAll(): Promise<void> {
    // Clear any existing timer
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    // Process all remaining logs
    const promises = this.logQueue.map((entry) => api.post("/log", entry));
    this.logQueue = [];

    try {
      await Promise.all(promises);
    } catch (error) {
      console.error("Failed to flush logs:", error);
    }

    this.isProcessing = false;
  }
}

// Create and export a singleton instance
export const logger = new LogWorker(
  "ella-web",
  import.meta.env.VITE_APP_VERSION
);

// Create a hook for using the logger in React components
export function useLogger() {
  return logger;
}
