import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
// import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { logFormats } from "../utils/log-formats";

interface MyRouterContext {
  // The ReturnType of your useAuth hook or the value of your AuthContext
  auth: {
    isAuthenticated: boolean;
    organizationId: string | number;
    userId: string | number;
  };
}
export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: App,
  notFoundComponent: () => <div>Not Found1 121</div>,
  errorComponent: (e) => {
    logFormats.app.error(
      e.info?.componentStack || "Error in root route",
      e.error
    );
    return <div>Error in root route</div>;
  },
});

function App() {
  return (
    <>
      <Outlet />

      {/* <TanStackRouterDevtools position="top-right" /> */}
    </>
  );
}
