import { logger } from "./logWorker";

/**
 * Structured logging formats for common use cases
 */
export const logFormats = {
  /**
   * Log authentication events
   */
  auth: {
    /**
     * Log successful authentication
     */
    success: (method: string, userId?: string) => {
      logger.info(`Authentication successful via ${method}`, {
        event: "auth_success",
        method,
        userId,
      });
    },
    /**
     * Log authentication failures
     */
    failure: (method: string, error: any, details?: Record<string, any>) => {
      logger.error(`Authentication failed via ${method}`, {
        event: "auth_failure",
        method,
        error: error?.message || String(error),
        errorCode: error?.code,
        ...details,
      });
    },
    /**
     * Log user logout
     */
    logout: (userId?: string) => {
      logger.info("User logged out", {
        event: "auth_logout",
        userId,
      });
    },
  },

  /**
   * Log API interactions
   */
  api: {
    /**
     * Log API request
     */
    request: (
      endpoint: string,
      method: string,
      params?: Record<string, any>
    ) => {
      logger.debug(`API Request: ${method} ${endpoint}`, {
        event: "api_request",
        endpoint,
        method,
        params,
      });
    },
    /**
     * Log API response
     */
    response: (endpoint: string, status: number, duration?: number) => {
      logger.debug(`API Response: ${endpoint} (${status})`, {
        event: "api_response",
        endpoint,
        status,
        duration,
      });
    },
    /**
     * Log API error
     */
    error: (
      endpoint: string,
      error: any,
      requestData?: Record<string, any>
    ) => {
      logger.error(`API Error: ${endpoint}`, {
        event: "api_error",
        endpoint,
        error: error?.message || String(error),
        status: error?.response?.status,
        requestData,
      });
    },
  },

  /**
   * Log user actions
   */
  user: {
    /**
     * Log a user action
     */
    action: (action: string, details?: Record<string, any>) => {
      logger.info(`User action: ${action}`, {
        event: "user_action",
        action,
        ...details,
      });
    },
  },

  /**
   * Log application lifecycle events
   */
  app: {
    /**
     * Log app initialization
     */
    init: (details?: Record<string, any>) => {
      logger.info("Application initialized", {
        event: "app_init",
        ...details,
      });
    },
    /**
     * Log feature flag status
     */
    featureFlag: (flag: string, enabled: boolean) => {
      logger.info(
        `Feature flag ${flag} is ${enabled ? "enabled" : "disabled"}`,
        {
          event: "feature_flag",
          flag,
          enabled,
        }
      );
    },
    /**
     * Log performance metrics
     */
    performance: (metric: string, value: number, unit: string) => {
      logger.info(`Performance: ${metric} = ${value}${unit}`, {
        event: "performance",
        metric,
        value,
        unit,
      });
    },
    /**
     * Log errors not related to API or authentication
     */
    error: (message: string, error: any, details?: Record<string, any>) => {
      logger.error(message, {
        event: "app_error",
        error: error?.message || String(error),
        stack: error?.stack,
        ...details,
      });
    },
    /**
     * Log info current state of the application
     */
    info: (message: string, details?: Record<string, any>) => {
      logger.info(message, {
        event: "app_info",
        ...details,
      });
    },
    /**
     * Log navigation events
     */
    navigation: (from: string, to: string) => {
      logger.info(`Navigation from ${from} to ${to}`, {
        event: "navigation",
        from,
        to,
      });
    },
  },
};
