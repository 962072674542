import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { api } from "@ella/web/queryClient";
import useSubDomain from "./useSubDomain";
import { logFormats } from "../utils/log-formats";

interface Organization {
  id: number;
  organizationName: string;
  address: string | null;
  country: string | null;
  subdomain: string;
  status: number;
  ownerId: string | null;
  timezone: string | null;
  numberOfTerms: number | null;
  numberOfGradingPeriods: number | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

interface UseOrganizationOptions {
  enabled?: boolean;
}

/**
 * Hook to fetch organization information by domain
 * @param domain - The domain to look up
 * @param options - Query options
 * @returns Organization data and query state
 */
export const useOrganization = (options?: UseOrganizationOptions) => {
  const { subdomain } = useSubDomain();
  const domain = subdomain;

  const fetchOrganization = async (domain: string): Promise<Organization> => {
    try {
      const response = await api.get(`/v1/organizations/domain`, {
        params: { domain },
      });
      if (response.status !== 200) {
        throw new Error("Failed to fetch organization information");
      }
      return response.data.data;
    } catch (error) {
      logFormats.api.error("useOrganization", error);
      // Handle error
      if (axios.isAxiosError(error) && error.response) {
        throw new Error(
          error.response.data.message ||
            "Failed to fetch organization information"
        );
      }

      throw error;
    }
  };

  const query = useQuery({
    queryKey: ["organization", domain],
    queryFn: () => fetchOrganization(domain || ""),
    staleTime: 1000 * 60 * 1, // 1 minutes
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchOnMount: true,
    enabled: !!domain && !!domain.length,
    // retry: true,
    ...options,
  });

  return {
    organization: query.data,
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
    isError: query.isError,
    error: query.error,
    refetch: query.refetch,
  };
};

export default useOrganization;
