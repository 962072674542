import { useState, useEffect } from "react";

interface UseSubDomainResult {
  subdomain: string | null;
  isDefaultDomain: boolean;
  isIgnoredDomain: boolean;
  isValidDomain: boolean;
  fullUrl: (path?: string) => string;
}

/**
 * Hook to extract and manage subdomain information
 * @returns Current subdomain information and utility functions
 */
export const useSubDomain = (): UseSubDomainResult => {
  const [subdomain, setSubdomain] = useState<string | null>(null);
  const [isDefaultDomain, setIsDefaultDomain] = useState(false);
  const [isIgnoredDomain, setIsIgnoredDomain] = useState(false);
  const [isValidDomain, setIsValidDomain] = useState(false);

  useEffect(() => {
    const baseDomain = import.meta.env.VITE_BASE_URL || "";
    const ignoreDomains = (import.meta.env.VITE_IGNORE_DOMAIN || "").split(",");
    const defaultSubdomain = import.meta.env.VITE_DEFAULT_SUBDOMAIN || "";
    const currentHostname = window.location.hostname;

    if (/^\d+\.\d+\.\d+\.\d+$/.test(currentHostname)) {
      setSubdomain("");
      setIsDefaultDomain(false);
      setIsIgnoredDomain(true);
      setIsValidDomain(false);
      return;
    }

    // Extract subdomain from hostname
    const subdomainParts = currentHostname
      .split(".")
      .filter((part) => !baseDomain.includes(part));

    let extractedSubdomain = subdomainParts.length > 2 ? subdomainParts[0] : "";

    if (subdomainParts[1] === "localhost") {
      extractedSubdomain = subdomainParts[0];
    }
    // Special case: handle 'www' subdomain (often treated specially)
    if (extractedSubdomain === "www") {
      extractedSubdomain = "";
    }

    // Check if we're on default domain
    const isDefault =
      currentHostname === baseDomain || extractedSubdomain === defaultSubdomain;

    // Check if we're on an ignored domain
    const isIgnored = ignoreDomains.includes(currentHostname);

    // A domain is considered valid if it's not the base domain and not ignored
    const isValid = extractedSubdomain !== null && !isIgnored;

    setSubdomain(extractedSubdomain);
    setIsDefaultDomain(isDefault);
    setIsIgnoredDomain(isIgnored);
    setIsValidDomain(isValid);
  }, [window.location.hostname]);

  /**
   * Generates a full URL with the current subdomain
   * @param path - Optional path to append to the URL
   * @returns Complete URL with subdomain
   */
  const fullUrl = (path = "/"): string => {
    const baseDomain = import.meta.env.VITE_BASE_URL || "";
    const port = window.location.port ? `:${window.location.port}` : "";

    if (!subdomain) {
      return `${window.location.protocol}//${baseDomain}${port}${path}`;
    }

    return `${window.location.protocol}//${subdomain}.${baseDomain}${port}${path}`;
  };

  return {
    subdomain,
    isDefaultDomain,
    isIgnoredDomain,
    isValidDomain,
    fullUrl,
  };
};

export default useSubDomain;
