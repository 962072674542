import { useMutation } from "@tanstack/react-query";
import { auth } from "../firebase";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import {
  activeWithGoogle,
  signInWithGoogle,
} from "../modules/auth/services/activeWithGoogle";
import { logFormats } from "../utils/log-formats";

const provider = new GoogleAuthProvider();
provider.addScope("profile");
provider.addScope("email");
provider.setCustomParameters({
  prompt: "select_account",
});
const useGoogle = () => {
  const { isSuccess, data, isPending, mutate } = useMutation({
    mutationKey: ["activeWithGoogle"],
    onError: async (e) => {
      logFormats.auth.failure("Google", e);
      await auth.signOut();
    },
    onSuccess: async () => {
      await auth.signOut();
    },
    mutationFn: async ({
      idToken,
      inviteToken,
    }: {
      idToken: string;
      inviteToken: string;
    }) => await activeWithGoogle(inviteToken, idToken),
  });

  const googleCreateSession = useMutation({
    mutationKey: ["googleCreateSession"],
    onError: async (e) => {
      logFormats.auth.failure("Google", e);
      await auth.signOut();
    },
    onSuccess: async () => {
      await auth.signOut();
    },
    mutationFn: async ({
      idToken,
      organizationId,
    }: {
      idToken: string;
      organizationId: number;
    }) => await signInWithGoogle(idToken, organizationId),
  });

  const handleSignIn = (inviteToken: string) => {
    return new Promise((resolve, reject) => {
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const token = await result.user.getIdToken();
          if (token) {
            mutate(
              {
                idToken: token,
                inviteToken: inviteToken,
              },
              {
                onSuccess: (data) => resolve(data),
                onError: (error) => {
                  logFormats.auth.failure("Google", error);
                  reject(error);
                },
              }
            );
          }
        })
        .catch((error) => {
          logFormats.auth.failure("Google", error);
          console.log("error", error);
        });
    });
  };

  const handleSignInWithSession = (organizationId: number) => {
    return new Promise((resolve, reject) => {
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const token = await result.user.getIdToken();
          if (token) {
            googleCreateSession.mutate(
              {
                idToken: token,
                organizationId: organizationId,
              },
              {
                onSuccess: (data) => resolve(data),
                onError: (error) => {
                  logFormats.auth.failure("Google", error);
                  reject(error);
                },
              }
            );
          }
        })
        .catch((error) => {
          logFormats.auth.failure("Google", error);
          reject(error);
        });
    });
  };

  return {
    isSuccess,
    data,
    isPending: isPending || googleCreateSession.isPending,
    singIn: handleSignIn,
    signInWithSession: handleSignInWithSession,
    sigOut: auth.signOut,
  };
};

export default useGoogle;
